<template>
  <div class="home-view">
    <div class="home-header">
      <div class="header-text">
        We Create <span>Mobile Apps</span> That Our Users Love
      </div>

      <div class="building-image" />

      <MChevron
        class="icon"
        type="down"
        @click="onChevronClick"
      />
    </div>

    <div class="product-list">
      <Header text="Our Products" />

      <ProductListItem
        v-for="(item, index) in products"
        :key="item.id"
        :index="index"
        :product="item"
      />
    </div>

    <MButton
      class="view-apps-button"
      radius
      @click="onViewAppsClick"
    >
      View All Apps

      <MChevron
        class="icon"
        type="right"
      />
    </MButton>
  </div>
</template>

<script>
import Products from '@/models/Products';
import Header from '@/components/shared/Header';
import MChevron from '@/components/shared/MChevron';
import MButton from '@/components/shared/MButton';
import ProductListItem from '@/components/ProductListItem';

export default {
  name: 'HomeView',

  components: {
    Header,
    MChevron,
    MButton,
    ProductListItem,
  },

  computed: {
    products () {
      return Products.getProductsByIds(['funface', 'portrait', 'voitex']);
    },
  },

  methods: {
    onChevronClick () {
      const scrollProductList = document.getElementsByClassName('product-list')[0].offsetTop;
      scrollTo({
        top: scrollProductList,
        behavior: 'smooth',
      });
    },

    onViewAppsClick () {
      this.$router.push({ name: 'products' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.home-view {
  .home-header {
    position: relative;
    padding-top: 106px;
    height: 966px;
    background-color: $color-black;
    background-image: url('@/assets/images/home-pattern.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% 472px;

    .header-text {
      margin: 0 auto;
      max-width: 661px;
      text-align: center;
      font-family: 'Gilroy-Bold';
      font-size: 60px;
      color: $color-white;

      span {
        color: $color-malachite;
      }
    }

    .building-image {
      position: absolute;
      bottom: -34px;
      left: 50%;
      transform: translateX(-50%);
      width: 716px;
      height: 687px;
      background-image: url('@/assets/images/building.png');
      background-size: cover;
    }

    .icon {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      color: $color-white;
      cursor: pointer;

      &::after {
        width: 13px;
        height: 13px;
      }
    }
  }

  .product-list {
    margin: 0 auto;
    max-width: 890px;
    width: 100%;

    header {
      margin-bottom: 56px;
    }
  }

  .view-apps-button {
    display: flex;
    align-items: center;
    margin: 120px auto 140px auto;

    .icon {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    .home-header {
      .icon {
        display: none;
      }
    }

    .product-list {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 500px) {
    .home-header {
      padding-top: 104px;
      height: 744px;

      .header-text {
        max-width: 331px;
        font-size: 30px;
      }

      .building-image {
        bottom: 0;
        width: 100%;
        height: 550px;
        background-image: url('@/assets/images/building-mobile.png');
        background-size: cover;
      }
    }

    .product-list {
      header {
        margin-bottom: 0;
      }
    }

    .view-apps-button {
      margin: 50px auto 60px auto;
    }
  }
}
</style>
