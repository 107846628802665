<template>
  <button
    :class="classNames"
    v-bind="$attrs"
  >
    <div class="content">
      <slot />
    </div>
  </button>
</template>

<script>
const BUTTON_VARIANTS = Object.freeze({
  primary: '-primary',
  secondary: '-secondary',
  link: '-link',
});

const BUTTON_SIZES = Object.freeze({
  small: '-small',
  medium: '-medium',
  big: '-big',
});

export default {
  name: 'MButton',

  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => {
        const variants = Object.keys(BUTTON_VARIANTS);
        if (!variants.includes(value)) {
          console.error(`Invalid prop ${value}. Give one of the following: ${variants.join(',')}`);
          return false;
        }
        return true;
      },
    },

    size: {
      type: String,
      default: 'medium',
      validator: (value) => {
        const sizes = Object.keys(BUTTON_SIZES);
        if (!sizes.includes(value)) {
          console.error(`Invalid prop ${value}. Give one of the following: ${sizes.join(',')}`);
          return false;
        }
        return true;
      },
    },

    fluid: {
      type: Boolean,
      default: false,
    },

    radius: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classNames () {
      return {
        'm-button': true,
        '-fluid': this.fluid,
        '-radius': this.radius,
        [BUTTON_VARIANTS[this.variant]]: true,
        [BUTTON_SIZES[this.size]]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.m-button {
  display: inline-block;
  padding: 0;
  outline: none;
  border: 1px solid transparent;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SF Pro Display';
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
  }

  &.-primary {
    color: $color-white;
    background-color: $color-azure-radiance;

    &:hover {
      opacity: .8;
    }
  }

  &.-secondary {
    color: $color-white;
    background-color: $color-mine-shaft;

    &:hover {
      opacity: .8;
    }
  }

  &.-link {
    padding: 0;
    color: $color-white;
    background-color: transparent;

    &:hover {
      opacity: .8;
    }
  }

  &.-small {
    padding: 12px 22px;
  }

  &.-medium {
    padding: 24px 75px;
  }

  &.-big {
    padding: 20px 132px;
  }

  &.-radius {
    border-radius: 50px;
  }
}
</style>
