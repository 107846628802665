<template>
  <div :class="classNames">
    <img
      :src="getProductImage(product.mainScreenshot)"
      :alt="product.name"
    >

    <div class="product-detail">
      <div class="product-info">
        <img
          :src="getProductImage(product.logo)"
          :alt="product.name"
        >

        <div class="product-name">
          {{ product.name }}
        </div>
      </div>

      <div class="product-description">
        <p class="description-title">
          {{ product.description.title }}
        </p>

        <p class="description-item">
          {{ product.description.items[0] }}
        </p>
      </div>

      <AppStoreRedirects
        :store-link="product.appStoreUrl"
        :qr-code="product.qrCode"
      />
    </div>

    <div class="divider" />
  </div>
</template>

<script>
import { getImage } from '@/utils/images-helper';
import AppStoreRedirects from '@/components/shared/AppStoreRedirects';

export default {
  name: 'ProductListItem',

  components: {
    AppStoreRedirects,
  },

  props: {
    index: {
      type: Number,
      default: () => null,
    },
    product: {
      type: Object,
      required: true,
    },
  },

  computed: {
    classNames () {
      return {
        'product-list-item': true,
        'is-reverse': this.index % 2 === 1,
      };
    },
  },

  methods: {
    getProductImage (imageName) {
      return getImage({ imageName });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.product-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &.is-reverse {
    flex-direction: row-reverse;
  }

  &:not(:last-child) {
    margin-bottom: 80px;
  }

  .product-detail {
    max-width: 438px;

    .product-info {
      display: flex;
      align-items: center;

      img {
        width: 96px;
        height: 96px;
      }

      .product-name {
        margin-left: 24px;
        font-size: 32px;
        font-weight: 700;
      }
    }

    .product-description {
      margin-top: 40px;

      .description-title {
        font-size: 18px;
        font-weight: 700;
      }

      .description-item {
        font-size: 16px;
      }
    }
  }

  .divider {
    display: none;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 0 !important;

    &.is-reverse {
      flex-direction: column;
    }

    .product-detail {
      max-width: 330px;

      ::v-deep .app-store-redirects {
        .qr-code {
          margin-left: 31px;
          width: 130px;
          height: 130px;
        }
      }
    }

    .divider {
      display: block;
      margin-top: 30px;
      width: 100%;
      border-bottom: 1px solid $color-alto;
    }

    &:last-child {
      .divider {
        display: none;
      }
    }
  }
}
</style>
