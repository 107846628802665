<template>
  <div class="app-store-redirects">
    <MAppStoreButton :store-link="storeLink" />

    <img
      class="qr-code"
      :src="getQrCode"
      alt="qr-code"
    >
  </div>
</template>

<script>
import { getImage } from '@/utils/images-helper';
import MAppStoreButton from '@/components/shared/MAppStoreButton';

export default {
  name: 'AppStoreRedirects',

  components: {
    MAppStoreButton,
  },

  props: {
    storeLink: {
      type: String,
      required: true,
    },

    qrCode: {
      type: String,
      required: true,
    },
  },

  computed: {
    getQrCode () {
      return getImage({ imageName: this.qrCode });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-store-redirects {
  display: flex;
  align-items: center;

  img {
    margin-left: 82px;
    width: 164px;
    height: 164px;
  }
}
</style>
