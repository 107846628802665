<template>
  <div class="footer">
    <div class="redirects-link-container">
      <div class="logo-icon-container">
        <img
          class="mellon-technology-logo"
          :src="mellonTechnologyLogo"
          alt="mellon-technology"
        >

        <div class="social-links">
          <img
            :src="linkedinIcon"
            alt="linkedin-icon"
            @click="onSocialClick('linkedin')"
          >

          <img
            :src="instagramIcon"
            alt="instagram-icon"
            @click="onSocialClick('instagram')"
          >
        </div>
      </div>

      <div class="redirects-link-items">
        <MButton
          v-for="item in menu"
          :key="item.text"
          class="link-item"
          :variant="item.variant"
          :size="null"
          @click="onMenuClick(item.redirect)"
        >
          {{ item.text }}
        </MButton>
      </div>
    </div>

    <div class="divider" />

    <div class="social-link-container">
      <div class="copyright">
        &copy; Mellon Technology {{ currentYear }}
      </div>

      <div class="social-links">
        <img
          :src="linkedinIcon"
          alt="linkedin-icon"
          @click="onSocialClick('linkedin')"
        >

        <img
          :src="instagramIcon"
          alt="instagram-icon"
          @click="onSocialClick('instagram')"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getImage } from '@/utils/images-helper';
import MButton from '@/components/shared/MButton';

const SOCIAL_LINKS = Object.freeze({
  linkedin: 'https://www.linkedin.com/company/mellontechnology/',
  instagram: 'https://www.instagram.com/mellon.technology/',
});

export default {
  name: 'Footer',

  components: {
    MButton,
  },

  computed: {
    mellonTechnologyLogo () {
      return getImage({ imageName: 'mellon-technology-logo' });
    },

    linkedinIcon () {
      return getImage({ imageName: 'linkedin-icon' });
    },

    instagramIcon () {
      return getImage({ imageName: 'instagram-icon' });
    },

    menu () {
      return [
        {
          text: 'Home',
          variant: 'link',
          redirect: 'home',
        },
        {
          text: 'Products',
          variant: 'link',
          redirect: 'products',
        },
        {
          text: 'Careers',
          variant: 'link',
          redirect: 'careers',
        },
        {
          text: 'About Us',
          variant: 'link',
          redirect: 'about',
        },
        {
          text: 'Contact',
          variant: 'link',
          redirect: 'contact',
        },
      ];
    },

    currentYear () {
      return new Date().getFullYear();
    },
  },

  methods: {
    onMenuClick (redirect) {
      this.$router.push({ name: redirect });
    },

    onSocialClick (type) {
      window.open(SOCIAL_LINKS[type], '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars';

.footer {
  position: relative;
  padding: 30px 220px 35px 220px;
  height: 208px;
  background-color: $color-black;

  .redirects-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-icon-container {
      .social-links {
        display: none;
      }
    }

    .redirects-link-items {
      .link-item {
        &:not(:last-child) {
          margin-right: 62px;
        }

        ::v-deep .content {
          font-size: 16px;
        }
      }
    }
  }

  .divider {
    margin: 28px 0 34px 0;
    width: 100%;
    border-bottom: 2px solid $color-white;
    opacity: .15;
  }

  .social-link-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .copyright {
      margin-right: 32px;
      font-size: 18px;
      font-weight: 500;
      color: $color-gray;
    }

    .social-links {
      img {
        cursor: pointer;

        &:first-child {
          margin-right: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 30px 110px 35px 110px;

    .redirects-link-container {
      .redirects-link-items {
        .link-item {
          &:not(:last-child) {
            margin-right: 31px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    padding: 30px 50px 35px 50px;

    .redirects-link-container {
      .redirects-link-items {
        .link-item {
          &:not(:last-child) {
            margin-right: 31px;
          }
        }
      }
    }

    .social-link-container {
      .copyright {
        font-size: 16px;
      }

      .social-links {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    padding: 39px 30px 44px 30px;
    height: 219px;

    .redirects-link-container {
      align-items: flex-start;
      margin-bottom: 41px;

      .logo-icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .mellon-technology-logo {
          margin-bottom: 16px;
          width: 110px;
          height: 36px;
        }

        .social-links {
          display: flex;

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 18px;
            }
          }
        }
      }

      .redirects-link-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 160px;
        height: 82px;

        .link-item {
          font-size: 15px;

          &:not(:nth-child(3)) {
            margin-bottom: 10px;
          }
        }
      }
    }

    .divider {
      display: none;
    }

    .social-link-container {
      .social-links {
        display: none;
      }
    }
  }
}
</style>
