<template>
  <MButton
    class="m-app-store-button"
    radius
    size="small"
    @click="onClick"
  >
    <img
      :src="appStoreIcon"
      alt="App Store"
    >

    <div class="store-text">
      <div>Available on</div>
      <div>App Store</div>
    </div>
  </MButton>
</template>

<script>
import { getImage } from '@/utils/images-helper';
import MButton from '@/components/shared/MButton';

export default {
  name: 'MAppStoreButton',

  components: {
    MButton,
  },

  props: {
    storeLink: {
      type: String,
      required: true,
    },
  },

  computed: {
    appStoreIcon () {
      return getImage({ imageName: 'app-store-icon' });
    },
  },

  methods: {
    onClick () {
      window.open(this.storeLink, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

.m-app-store-button {
  width: 192px;
  background-color: $color-black !important;

  .store-text {
    margin-left: 12px;
    text-align: left;

    div {
      &:first-child {
        font-size: 10px;
        font-weight: 400;
      }

      &:last-child {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
</style>
