<template>
  <i :class="classNames" />
</template>

<script>
const TYPES = Object.freeze({
  right: '-right',
  down: '-down',
});

export default {
  name: 'MChevron',

  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => {
        const types = Object.keys(TYPES);
        if (!types.includes(value)) {
          console.error(`Invalid prop ${value}. Give one of the following: ${types.join(',')}`);
          return false;
        }
        return true;
      },
    },
  },

  computed: {
    classNames () {
      return {
        'm-chevron': true,
        [TYPES[this.type]]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.m-chevron {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 4px solid transparent;
  border-radius: 100px;

  &.-right {
    &::after {
      content: "";
      position: absolute;
      top: 4px;
      right: 6px;
      width: 10px;
      height: 10px;
      display: block;
      box-sizing: border-box;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(-45deg);
    }
  }

  &.-down {
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 4px;
      width: 10px;
      height: 10px;
      display: block;
      box-sizing: border-box;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
    }
  }
}
</style>
