<template>
  <header>
    {{ text }}
  </header>
</template>

<script>
export default {
  name: 'Header',

  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars.scss';

header {
  margin-top: 90px;
  font-family: 'Gilroy-Bold';
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
    font-size: 36px;
  }
}

</style>
