<template>
  <div class="layout">
    <Navbar />

    <router-view />
  </div>

  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';

export default {
  name: 'App',

  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.layout {
  min-height: calc(100vh - 208px);

  @media screen and (max-width: 500px) {
    min-height: calc(100vh - 219px);
  }
}
</style>
