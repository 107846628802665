class Products {
  constructor () {
    this.products = [
      {
        id: 'voitex',
        name: 'Voitex',
        rating: 4.5,
        appStoreUrl: 'https://apps.apple.com/tr/app/voitex-text-to-speech/id1559021771',
        playStoreUrl: '',
        subtitle: 'Text to Speech',
        description: {
          title: 'Don’t you have time for reading, then listen.',
          items: [
            'Voitex is an intelligent text-to-speech audio-reader that turns your documents into interactive audiobooks so that you can save time get more knowledge and stay focused.',
            'Voitex is your personal reading assistant, Voitex can read books documents and articles while you are doing your job.',
            'Personalize your listening experience with your own content, HD and natural voices and accents, find the perfect reading speed, while reading texts are highlighted for easy to track.',
            'Earn time and learn knowledge.',
          ],
        },
        logo: 'voitex-logo',
        productLogo: 'voitex-product-logo',
        mainScreenshot: 'voitex-main-screenshot',
        detailScreenshot: 'voitex-detail-screenshot',
        screenshots: [
          'voitex-1',
          'voitex-2',
          'voitex-3',
          'voitex-4',
          'voitex-5',
        ],
        qrCode: 'voitex-qr-code',
      },
      {
        id: 'funface',
        name: 'Funface',
        rating: 4.5,
        appStoreUrl: 'https://apps.apple.com/tr/app/face-swap-video-funface/id1587663229',
        playStoreUrl: '',
        subtitle: 'Face Morph, Meme Creator',
        description: {
          title: 'Swap faces, share fun with your friends!',
          items: [
            'Funface combine fun with always up-to-date faces, backgrounds, videos, GIFs, images and other type of contents, we are always ready to entertainment.',
            'You can swap your face with celebrities and popular people for creating funny contents and share with your friends. Prepare your or your friend’s funny videos with pre-defined or your own contents.',
            'Become who ever and where ever you want with our face swap, face editor and face morph technology, new way of meme creator.',
          ],
        },
        logo: 'funface-logo',
        productLogo: 'funface-product-logo',
        mainScreenshot: 'funface-main-screenshot',
        detailScreenshot: 'funface-detail-screenshot',
        screenshots: [
          'funface-1',
          'funface-2',
          'funface-3',
          'funface-4',
          'funface-5',
        ],
        qrCode: 'funface-qr-code',
      },
      {
        id: 'ombre',
        name: 'Ombre',
        rating: 4.5,
        appStoreUrl: '',
        playStoreUrl: '',
        subtitle: 'Hairstyle Try On',
        description: {
          title: 'Change your hairstyle with amazing colors!',
          items: [
            'Ombre offers you new colors for your hair. Decide on your hair style with rich color options. Try the colors you love with the realtime camera or with the photo or video from your album.',
            'Ombre gives you options to share after changing your hair color.',
            'Ombre, you can renew your style with the most suitable color for yourself, also you can be a fan of football, basketball, etc. You can share that you are a passionate fan with the colors of sports clubs.',
            'You will love how quickly you can see the results with Ombre.',
          ],
        },
        logo: 'ombre-logo',
        productLogo: 'ombre-product-logo',
        mainScreenshot: '',
        detailScreenshot: 'ombre-detail-screenshot',
        screenshots: [
          'ombre-1',
          'ombre-2',
          'ombre-3',
          'ombre-4',
          'ombre-5',
        ],
        qrCode: 'ombre-qr-code',
      },
      {
        id: 'dark-room',
        name: 'DarkRoom',
        rating: 4.5,
        appStoreUrl: 'https://apps.apple.com/tr/app/presets-filters-darkroom/id1608806963',
        playStoreUrl: '',
        subtitle: 'Hairstyle Try On',
        description: {
          title: 'Change your hairstyle with amazing colors!',
          items: [
            'Ombre offers you new colors for your hair. Decide on your hair style with rich color options. Try the colors you love with the realtime camera or with the photo or video from your album.',
            'Ombre gives you options to share after changing your hair color.',
            'Ombre, you can renew your style with the most suitable color for yourself, also you can be a fan of football, basketball, etc. You can share that you are a passionate fan with the colors of sports clubs.',
            'You will love how quickly you can see the results with Ombre.',
          ],
        },
        logo: 'dark-room-logo',
        productLogo: 'dark-room-product-logo',
        mainScreenshot: '',
        detailScreenshot: 'dark-room-detail-screenshot',
        screenshots: [],
        qrCode: 'dark-room-qr-code',
      },
      {
        id: 'storist',
        name: 'Storist',
        rating: 4.5,
        appStoreUrl: 'https://apps.apple.com/tr/app/storist-story-maker-collage/id1581096268',
        playStoreUrl: '',
        subtitle: 'Photo Editor & Templates',
        description: {
          title: 'Create Instagram story using practical story templates!',
          items: [
            'Are you ready to create elegant Instagram stories using practical story templates that you can share with your followers via Storist?',
            'You can impress your followers and gain more followers via stunning stories with the best story maker Storist. You can edit photos and add a different perspective to your story with professionally prepared story templates for each category.',
            'Storist helps create unique and stunning insta story, it has lots of story templates, photo editor, also has easy and useful user interface.',
            'Start to create stunning stories, best story maker ever made.',
          ],
        },
        logo: 'storist-logo',
        productLogo: 'storist-product-logo',
        mainScreenshot: '',
        detailScreenshot: 'storist-detail-screenshot',
        screenshots: [
          'storist-1',
          'storist-2',
          'storist-3',
          'storist-4',
          'storist-5',
        ],
        qrCode: 'storist-qr-code',
      },
      {
        id: 'rmv',
        name: 'RMV',
        rating: 4.5,
        appStoreUrl: 'https://apps.apple.com/tr/app/remove-unwanted-object-rmv/id1602813429',
        playStoreUrl: '',
        subtitle: 'Remove Unwanted Objects',
        description: {
          title: 'Swap faces, share fun with your friends!',
          items: [
            'Funface combine fun with always up-to-date faces, backgrounds, videos, GIFs, images and other type of contents, we are always ready to entertainment.',
            'You can swap your face with celebrities and popular people for creating funny contents and share with your friends. Prepare your or your friend’s funny videos with pre-defined or your own contents.',
            'Become who ever and where ever you want with our face swap, face editor and face morph technology, new way of meme creator.',
          ],
        },
        logo: 'rmv-logo',
        productLogo: 'rmv-product-logo',
        mainScreenshot: '',
        detailScreenshot: 'rmv-detail-screenshot',
        screenshots: [
          'rmv-1',
          'rmv-2',
          'rmv-3',
          'rmv-4',
          'rmv-5',
        ],
        qrCode: 'rmv-qr-code',
      },
      {
        id: 'portrait',
        name: 'Portrait',
        rating: 4.5,
        appStoreUrl: 'https://apps.apple.com/tr/app/photo-filter-ai-art-portrait/id1597949255',
        playStoreUrl: '',
        subtitle: 'Artistic Filters',
        description: {
          title: 'Paint your portrait with AI and turn it into a classic!',
          items: [
            'You can turn your photos and selfies into a work of art by painting them with AI. You can create a classic portrait with dozens of art filters and art effects. Portrait AI is a fully automatic portrait photo editor. Share your creations with your followers on Instagram or sell them as an NFT.',
            'With filters prepared using neural networks and artificial intelligence, you can make your portrait look as if artists such as Leonardo da Vinci, Warhol pop art, Van Gogh, Picasso or Dali painted it for you.',
            'Wondering what you might look like when you\'re old? You can rejuvenate or age yourself with AI. Share with your friends and see if they can recognize you.',
          ],
        },
        logo: 'portrait-logo',
        productLogo: 'portrait-product-logo',
        mainScreenshot: 'portrait-main-screenshot',
        detailScreenshot: 'portrait-detail-screenshot',
        screenshots: [
          'portrait-1',
          'portrait-2',
          'portrait-3',
          'portrait-4',
          'portrait-5',
        ],
        qrCode: 'portrait-qr-code',
      },
      {
        id: 'beautify',
        name: 'Beautify',
        rating: 4.5,
        appStoreUrl: 'https://apps.apple.com/tr/app/makeup-photo-editor-beautify/id1599516664',
        playStoreUrl: '',
        subtitle: 'Selfie Editing & Beauty Camera',
        description: {
          title: 'Live Makeup Camera & Best Selfie Editor!',
          items: [
            'Beautify instantly beautify your face, skin smoothing, whiten teeth, colorful eyes, lips, eyebrows, hair color changer and more..',
            `- Live Makeup Cam<br>
             - Lips<br>
             - Skin Smoothing<br>
             - Hair Color Changer<br>
             - Eyebrow<br>
             - Eyes<br>
             - Teeth Whitening<br>
            `,
            'Record or Capture yourself & Share with your friends!',
          ],
        },
        logo: 'beautify-logo',
        productLogo: 'beautify-product-logo',
        mainScreenshot: '',
        detailScreenshot: 'beautify-detail-screenshot',
        screenshots: [
          'beautify-1',
          'beautify-2',
          'beautify-3',
          'beautify-4',
          'beautify-5',
        ],
        qrCode: 'beautify-qr-code',
      },
      {
        id: 'remove-bg',
        name: 'Remove BG',
        rating: 4.5,
        appStoreUrl: '',
        playStoreUrl: '',
        subtitle: 'Background Eraser',
        description: {
          title: 'Swap faces, share fun with your friends!',
          items: [
            'Funface combine fun with always up-to-date faces, backgrounds, videos, GIFs, images and other type of contents, we are always ready to entertainment.',
            'You can swap your face with celebrities and popular people for creating funny contents and share with your friends. Prepare your or your friend’s funny videos with pre-defined or your own contents.',
            'Become who ever and where ever you want with our face swap, face editor and face morph technology, new way of meme creator.',
          ],
        },
        logo: 'remove-bg-logo',
        productLogo: 'remove-bg-product-logo',
        mainScreenshot: '',
        detailScreenshot: 'remove-bg-detail-screenshot',
        screenshots: [
          'remove-bg-1',
          'remove-bg-2',
          'remove-bg-3',
          'remove-bg-4',
          'remove-bg-5',
        ],
        qrCode: 'remove-bg-qr-code',
      },
    ];
  }

  getProducts () {
    return this.products;
  }

  getProductById (id) {
    return this.products.find(item => item.id === id);
  }

  getProductsByIds (ids) {
    return this.products.filter(item => ids.includes(item.id));
  }

  excludeProductsByIds (ids) {
    return this.products.filter(item => !ids.includes(item.id));
  }
}

export default new Products();
