<template>
  <nav :class="navbarClasses">
    <div class="navbar-container">
      <img
        src="@/assets/images/mellon-logo.svg"
        alt="mellon"
      >

      <div class="menu-container">
        <img
          class="menu-icon"
          src="@/assets/images/menu-icon.svg"
          alt="menu-icon"
          @click="onMenuIconClick"
        >

        <div :class="menuItemClasses">
          <MButton
            v-for="item in menu"
            :key="item.text"
            class="menu-item"
            :variant="item.variant"
            :size="item.size"
            :radius="item.radius"
            @click="onMenuClick(item.redirect)"
          >
            <img
              v-if="item.icon"
              class="menu-item-icon"
              :src="item.icon.src"
              :alt="item.icon.alt"
            >

            {{ item.text }}
          </MButton>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { getImage } from '@/utils/images-helper';
import MButton from '@/components/shared/MButton';

export default {
  name: 'Navbar',

  components: {
    MButton,
  },

  data () {
    return {
      isMenuOpen: false,
    };
  },

  computed: {
    navbarClasses () {
      return {
        navbar: true,
        opacity: this.$route.name === 'careers',
      };
    },

    menu () {
      return [
        {
          text: 'Home',
          variant: 'link',
          size: null,
          redirect: 'home',
        },
        {
          text: 'Products',
          variant: 'link',
          size: null,
          redirect: 'products',
        },
        {
          text: 'Careers',
          variant: 'link',
          size: null,
          redirect: 'careers',
        },
        {
          text: 'About Us',
          variant: 'link',
          size: null,
          redirect: 'about',
        },
        {
          text: 'Contact',
          variant: 'secondary',
          size: 'small',
          radius: true,
          icon: {
            src: getImage({ imageName: 'mail-icon' }),
            alt: 'mail-icon',
          },
          redirect: 'contact',
        },
      ];
    },

    menuItemClasses () {
      if (this.isMenuOpen) {
        return {
          'menu-items': true,
          'is-show': true,
        };
      }

      return {
        'menu-items': true,
        'is-hide': true,
      };
    },
  },

  methods: {
    onMenuIconClick () {
      this.isMenuOpen = !this.isMenuOpen;
    },

    onMenuClick (redirect) {
      this.isMenuOpen = false;
      this.$router.push({ name: redirect });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/vars';

.navbar {
  display: flex;
  align-items: center;
  padding: 0;
  height: 64px;
  background-color: $color-black;

  &.opacity {
    background-color: rgba(0, 0, 0, .5);
  }

  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;

    .menu-icon {
      display: none;
    }

    .menu-container {
      .menu-item {
        &:not(:last-child) {
          margin-right: 44px;
        }

        .menu-item-icon {
          margin-right: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .navbar-container {
      max-width: 1024px;
    }
  }

  @media screen and (max-width: 1024px) {
    align-items: flex-end;
    padding-bottom: 16px;
    height: 100px;

    .navbar-container {
      padding: 0 30px;

      .menu-icon {
        display: inherit;
        cursor: pointer;
        user-select: none;
      }

      .menu-items {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100px;
        right: 0;
        padding: 20px;
        border-radius: 0 0 0 8px;
        background-color: $color-black;

        &.is-show {
          display: flex;
          z-index: 1;
        }

        &.is-hide {
          display: none;
        }

        .menu-item {
          margin-right: 0 !important;
          padding: 20px 80px;
        }
      }
    }
  }
}
</style>
