import mellonLogo from '@/assets/images/mellon-logo.svg';
import mellonTechnologyLogo from '@/assets/images/mellon-technology-logo.svg';
import linkedinIcon from '@/assets/images/linkedin-icon.svg';
import instagramIcon from '@/assets/images/instagram-icon.svg';
import mailIcon from '@/assets/images/mail-icon.svg';
import menuIcon from '@/assets/images/menu-icon.svg';
import advancedTechnology from '@/assets/images/advanced-technology.svg';
import userCenteredDesign from '@/assets/images/user-centered-design.svg';
import creativeIdeas from '@/assets/images/creative-ideas.svg';
import valuableProducts from '@/assets/images/valuable-products.svg';
import mailBlackIcon from '@/assets/images/mail-black-icon.svg';
import pinIcon from '@/assets/images/pin-icon.svg';
import fileUploadIcon from '@/assets/images/file-upload-icon.svg';
import appStoreIcon from '@/assets/images/app-store-icon.svg';
import aboutUs from '@/assets/images/about-us.png';
import googleMap from '@/assets/images/google-map.png';
import joinBackground from '@/assets/images/join-background.png';
import joinUs from '@/assets/images/join-us.png';
import homePattern from '@/assets/images/home-pattern.png';
import building from '@/assets/images/building.png';
import buildingMobile from '@/assets/images/building-mobile.png';
import voitexLogo from '@/assets/images/voitex/voitex-logo.png';
import funfaceLogo from '@/assets/images/funface/funface-logo.png';
import ombreLogo from '@/assets/images/ombre/ombre-logo.png';
import darkRoomLogo from '@/assets/images/dark-room/dark-room-logo.png';
import storistLogo from '@/assets/images/storist/storist-logo.png';
import rmvLogo from '@/assets/images/rmv/rmv-logo.png';
import portraitLogo from '@/assets/images/portrait/portrait-logo.png';
import beautifyLogo from '@/assets/images/beautify/beautify-logo.png';
import removeBgLogo from '@/assets/images/remove-bg/remove-bg-logo.png';
import voitexProductLogo from '@/assets/images/voitex/voitex-product-logo.png';
import funfaceProductLogo from '@/assets/images/funface/funface-product-logo.png';
import ombreProductLogo from '@/assets/images/ombre/ombre-product-logo.png';
import darkRoomProductLogo from '@/assets/images/dark-room/dark-room-product-logo.png';
import storistProductLogo from '@/assets/images/storist/storist-product-logo.png';
import rmvProductLogo from '@/assets/images/rmv/rmv-product-logo.png';
import portraitProductLogo from '@/assets/images/portrait/portrait-product-logo.png';
import beautifyProductLogo from '@/assets/images/beautify/beautify-product-logo.png';
import removeBgProductLogo from '@/assets/images/remove-bg/remove-bg-product-logo.png';
import voitex1 from '@/assets/images/voitex/voitex-1.png';
import voitex2 from '@/assets/images/voitex/voitex-2.png';
import voitex3 from '@/assets/images/voitex/voitex-3.png';
import voitex4 from '@/assets/images/voitex/voitex-4.png';
import voitex5 from '@/assets/images/voitex/voitex-5.png';
import funface1 from '@/assets/images/funface/funface-1.png';
import funface2 from '@/assets/images/funface/funface-2.png';
import funface3 from '@/assets/images/funface/funface-3.png';
import funface4 from '@/assets/images/funface/funface-4.png';
import funface5 from '@/assets/images/funface/funface-5.png';
import ombre1 from '@/assets/images/ombre/ombre-1.png';
import ombre2 from '@/assets/images/ombre/ombre-2.png';
import ombre3 from '@/assets/images/ombre/ombre-3.png';
import ombre4 from '@/assets/images/ombre/ombre-4.png';
import ombre5 from '@/assets/images/ombre/ombre-5.png';
import storist1 from '@/assets/images/storist/storist-1.png';
import storist2 from '@/assets/images/storist/storist-2.png';
import storist3 from '@/assets/images/storist/storist-3.png';
import storist4 from '@/assets/images/storist/storist-4.png';
import storist5 from '@/assets/images/storist/storist-5.png';
import rmv1 from '@/assets/images/rmv/rmv-1.png';
import rmv2 from '@/assets/images/rmv/rmv-2.png';
import rmv3 from '@/assets/images/rmv/rmv-3.png';
import rmv4 from '@/assets/images/rmv/rmv-4.png';
import rmv5 from '@/assets/images/rmv/rmv-5.png';
import portrait1 from '@/assets/images/portrait/portrait-1.png';
import portrait2 from '@/assets/images/portrait/portrait-2.png';
import portrait3 from '@/assets/images/portrait/portrait-3.png';
import portrait4 from '@/assets/images/portrait/portrait-4.png';
import portrait5 from '@/assets/images/portrait/portrait-5.png';
import beautify1 from '@/assets/images/beautify/beautify-1.png';
import beautify2 from '@/assets/images/beautify/beautify-2.png';
import beautify3 from '@/assets/images/beautify/beautify-3.png';
import beautify4 from '@/assets/images/beautify/beautify-4.png';
import beautify5 from '@/assets/images/beautify/beautify-5.png';
import removeBg1 from '@/assets/images/remove-bg/remove-bg-1.png';
import removeBg2 from '@/assets/images/remove-bg/remove-bg-2.png';
import removeBg3 from '@/assets/images/remove-bg/remove-bg-3.png';
import removeBg4 from '@/assets/images/remove-bg/remove-bg-4.png';
import removeBg5 from '@/assets/images/remove-bg/remove-bg-5.png';
import voitexDetailScreenshot from '@/assets/images/voitex/voitex-detail-screenshot.png';
import funfaceDetailScreenshot from '@/assets/images/funface/funface-detail-screenshot.png';
import ombreDetailScreenshot from '@/assets/images/ombre/ombre-detail-screenshot.png';
import darkRoomDetailScreenshot from '@/assets/images/dark-room/dark-room-detail-screenshot.png';
import storistDetailScreenshot from '@/assets/images/storist/storist-detail-screenshot.png';
import rmvDetailScreenshot from '@/assets/images/rmv/rmv-detail-screenshot.png';
import portraitDetailScreenshot from '@/assets/images/portrait/portrait-detail-screenshot.png';
import beautifyDetailScreenshot from '@/assets/images/beautify/beautify-detail-screenshot.png';
import removeBgDetailScreenshot from '@/assets/images/remove-bg/remove-bg-detail-screenshot.png';
import voitexMainScreenshot from '@/assets/images/voitex/voitex-main-screenshot.png';
import funfaceMainScreenshot from '@/assets/images/funface/funface-main-screenshot.png';
import portraitMainScreenshot from '@/assets/images/portrait/portrait-main-screenshot.png';
import voitexQrCode from '@/assets/images/voitex/voitex-qr-code.png';
import funfaceQrCode from '@/assets/images/funface/funface-qr-code.png';
import ombreQrCode from '@/assets/images/ombre/ombre-qr-code.png';
import darkRoomQrCode from '@/assets/images/dark-room/dark-room-qr-code.png';
import storistQrCode from '@/assets/images/storist/storist-qr-code.png';
import rmvQrCode from '@/assets/images/rmv/rmv-qr-code.png';
import portraitQrCode from '@/assets/images/portrait/portrait-qr-code.png';
import beautifyQrCode from '@/assets/images/beautify/beautify-qr-code.png';
import removeBgQrCode from '@/assets/images/remove-bg/remove-bg-qr-code.png';

const imagesMap = {
  'mellon-logo': mellonLogo,
  'mellon-technology-logo': mellonTechnologyLogo,
  'linkedin-icon': linkedinIcon,
  'instagram-icon': instagramIcon,
  'mail-icon': mailIcon,
  'menu-icon': menuIcon,
  'advanced-technology': advancedTechnology,
  'user-centered-design': userCenteredDesign,
  'creative-ideas': creativeIdeas,
  'valuable-products': valuableProducts,
  'about-us': aboutUs,
  'google-map': googleMap,
  'pin-icon': pinIcon,
  'file-upload-icon': fileUploadIcon,
  'app-store-icon': appStoreIcon,
  'mail-black-icon': mailBlackIcon,
  'join-background': joinBackground,
  'join-us': joinUs,
  'home-pattern': homePattern,
  building: building,
  'building-mobile': buildingMobile,
  'voitex-logo': voitexLogo,
  'funface-logo': funfaceLogo,
  'ombre-logo': ombreLogo,
  'dark-room-logo': darkRoomLogo,
  'storist-logo': storistLogo,
  'rmv-logo': rmvLogo,
  'portrait-logo': portraitLogo,
  'beautify-logo': beautifyLogo,
  'remove-bg-logo': removeBgLogo,
  'voitex-product-logo': voitexProductLogo,
  'funface-product-logo': funfaceProductLogo,
  'ombre-product-logo': ombreProductLogo,
  'dark-room-product-logo': darkRoomProductLogo,
  'storist-product-logo': storistProductLogo,
  'rmv-product-logo': rmvProductLogo,
  'portrait-product-logo': portraitProductLogo,
  'beautify-product-logo': beautifyProductLogo,
  'remove-bg-product-logo': removeBgProductLogo,
  'voitex-1': voitex1,
  'voitex-2': voitex2,
  'voitex-3': voitex3,
  'voitex-4': voitex4,
  'voitex-5': voitex5,
  'funface-1': funface1,
  'funface-2': funface2,
  'funface-3': funface3,
  'funface-4': funface4,
  'funface-5': funface5,
  'ombre-1': ombre1,
  'ombre-2': ombre2,
  'ombre-3': ombre3,
  'ombre-4': ombre4,
  'ombre-5': ombre5,
  'storist-1': storist1,
  'storist-2': storist2,
  'storist-3': storist3,
  'storist-4': storist4,
  'storist-5': storist5,
  'rmv-1': rmv1,
  'rmv-2': rmv2,
  'rmv-3': rmv3,
  'rmv-4': rmv4,
  'rmv-5': rmv5,
  'portrait-1': portrait1,
  'portrait-2': portrait2,
  'portrait-3': portrait3,
  'portrait-4': portrait4,
  'portrait-5': portrait5,
  'beautify-1': beautify1,
  'beautify-2': beautify2,
  'beautify-3': beautify3,
  'beautify-4': beautify4,
  'beautify-5': beautify5,
  'remove-bg-1': removeBg1,
  'remove-bg-2': removeBg2,
  'remove-bg-3': removeBg3,
  'remove-bg-4': removeBg4,
  'remove-bg-5': removeBg5,
  'voitex-detail-screenshot': voitexDetailScreenshot,
  'funface-detail-screenshot': funfaceDetailScreenshot,
  'ombre-detail-screenshot': ombreDetailScreenshot,
  'dark-room-detail-screenshot': darkRoomDetailScreenshot,
  'storist-detail-screenshot': storistDetailScreenshot,
  'rmv-detail-screenshot': rmvDetailScreenshot,
  'portrait-detail-screenshot': portraitDetailScreenshot,
  'beautify-detail-screenshot': beautifyDetailScreenshot,
  'remove-bg-detail-screenshot': removeBgDetailScreenshot,
  'voitex-main-screenshot': voitexMainScreenshot,
  'funface-main-screenshot': funfaceMainScreenshot,
  'portrait-main-screenshot': portraitMainScreenshot,
  'voitex-qr-code': voitexQrCode,
  'funface-qr-code': funfaceQrCode,
  'ombre-qr-code': ombreQrCode,
  'dark-room-qr-code': darkRoomQrCode,
  'storist-qr-code': storistQrCode,
  'rmv-qr-code': rmvQrCode,
  'portrait-qr-code': portraitQrCode,
  'beautify-qr-code': beautifyQrCode,
  'remove-bg-qr-code': removeBgQrCode,
};

export function getImage ({ imageName = '' }) {
  return imagesMap[imageName] || null;
}
